import { trackEvent, trackDownload } from '../../plugins/matomo';

const exportSelectedPdfAction = {
	id: 'exportSelectedPdf',
	selectionType: 'multiple',
	label: 'actions.stopperformance.exportselected.pdf',
	functionality: 'READ_STOP_PERFORMANCE',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		let ids = [];

		for (let i = 0; i < registries.length; i++) {
			ids.push(registries[i].id);
		}

		trackDownload();
		this.$puiEvents.$emit('stop-performance-exportSelected', { format: 'pdf', data: ids });
	}
};

const exportSelectedCsvAction = {
	id: 'exportSelectedCsv',
	selectionType: 'multiple',
	label: 'actions.stopperformance.exportselected.csv',
	functionality: 'READ_STOP_PERFORMANCE',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		let ids = [];

		for (let i = 0; i < registries.length; i++) {
			ids.push(registries[i].id);
		}

		trackDownload();
		this.$puiEvents.$emit('stop-performance-exportSelected', { format: 'csv', data: ids });
	}
};

const exportSelectedExcelAction = {
	id: 'exportSelectedExcel',
	selectionType: 'multiple',
	label: 'actions.stopperformance.exportselected.excel',
	functionality: 'READ_STOP_PERFORMANCE',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		let ids = [];

		for (let i = 0; i < registries.length; i++) {
			ids.push(registries[i].id);
		}

		trackDownload();
		this.$puiEvents.$emit('stop-performance-exportSelected', { format: 'excel', data: ids });
	}
};

const dashboardAction = {
	id: 'dashboardAction',
	selectionType: 'general',
	label: 'actions.stopperformance.dashboard',
	functionality: 'ACTION_DB_STOPPERFORMANCE',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		trackDownload();
		this.$puiEvents.$emit('stop-performance-dashboard');
	}
};

const showDetailAction = {
	id: 'showDetailAction',
	selectionType: 'single',
	label: 'actions.stopperformance.showdetail',
	functionality: 'DETAIL_STOP_PERFORMANCE',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		console.log(action, model, registries);

		// override get method with getDetail
		model.url.get = model.url.getDetail;
		// get pk from selected registry
		const pk = this.$puiUtils.utf8ToB64(JSON.stringify({ id: registries[0].id }));

		const params = {
			router: this.$router,
			url: `/stopperformance/getDetail/${pk}`,
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	}
};

export default {
	actions: [exportSelectedPdfAction, exportSelectedCsvAction, exportSelectedExcelAction, dashboardAction, showDetailAction]
};
