<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="modelActions"
			:externalFilter="externalFilter"
			:modelColumnDefs="columnDefs"
			:navigableDetail="false"
		>
			<template slot="toolbarAdditionalBtns">
				<div class="pui-gridActionsBtn">
					<v-btn
						:id="`gridbtn-legend-${modelName}`"
						class="elevation-0 pui-toolbar__actionBtn primary white--text"
						:class="compClass"
						:title="$t('grid.stopperformance.legend_btn')"
						@click.native.stop="showLegend"
						icon
					>
						<v-icon>far fa-info</v-icon>
					</v-btn>
				</div>
			</template>
		</pui-datatable>

		<div class="text-center">
			<v-dialog v-model="dashboardDialog" eager>
				<stop-performance-dashboard-dialog v-if="dashboardDialog" :dashboard="dashboard" v-on:close-dialog="closeDashboard" />
			</v-dialog>
			<v-dialog v-model="legendDialog" max-width="800">
				<stop-performance-legend-dialog v-if="legendDialog" :modelName="modelName" v-on:close-dialog="closeLegend" />
			</v-dialog>
		</div>
	</div>
</template>

<script>
import StopPerformanceDashboardDialog from './StopPerformanceDashboardDialog.vue';
import StopPerformanceLegendDialog from './StopPerformanceLegendDialog.vue';
import StopPerformanceMixin from '@/mixins/StopPerformanceMixin.js';
import StopPerformanceActions from './StopPerformanceActions.js';
import { trackDownload } from '../../plugins/matomo';

export default {
	components: { StopPerformanceDashboardDialog, StopPerformanceLegendDialog },
	mixins: [StopPerformanceMixin],
	data() {
		return {
			modelName: 'stopperformance',
			modelActions: StopPerformanceActions.actions,
			columnDefs: {
				departureidletime: {
					render: (data, type, row, meta) => {
						return this.renderData(data, row, 'departureidletime');
					}
				},
				arrivalidletime: {
					render: (data, type, row, meta) => {
						return this.renderData(data, row, 'arrivalidletime');
					}
				},
				departureidletimeterminal: {
					render: (data, type, row, meta) => {
						return this.renderData(data, row, 'departureidletimeterminal');
					}
				},
				arrivalidletimeterminal: {
					render: (data, type, row, meta) => {
						return this.renderData(data, row, 'arrivalidletimeterminal');
					}
				},
				llegadaatraque: {
					render: (data, type, row, meta) => {
						return this.renderData(data, row, 'llegadaatraque');
					}
				},
				atraqueiniciooperaciones: {
					render: (data, type, row, meta) => {
						return this.renderData(data, row, 'atraqueiniciooperaciones');
					}
				},
				finoperacionesdesatraque: {
					render: (data, type, row, meta) => {
						return this.renderData(data, row, 'finoperacionesdesatraque');
					}
				},
				desatraquesalida: {
					render: (data, type, row, meta) => {
						return this.renderData(data, row, 'desatraquesalida');
					}
				}
			},
			dashboardDialog: false,
			dashboard: {},
			legendDialog: false
		};
	},
	computed: {
		externalFilter() {
			const berthPlannerPro = this.$store.getters.isBerthPlannerPro ? 1 : 0;

			let userQuays = [];
			let userQuaysId = [];
			userQuays = this.$store.getters.getUserBerths;
			userQuaysId = userQuays ? userQuays.map((quay) => quay.id) : null;

			const isOperator = this.$store.getters.isOperator;
			const isTerminal = this.$store.getters.isTerminal;

			if (isOperator) {
				return {
					rules: [
						{ field: 'berth_planner_pro', op: 'eq', data: berthPlannerPro },
						{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
						{ field: 'location_id', op: 'in', data: userQuaysId },
						{ field: 'operatorterminal', op: 'in', data: isOperator }
					]
				};
			}

			let defaultQuay = isTerminal ? this.$store.getters.getDefaultQuayByTerminal : null;

			if (!defaultQuay) {
				return {
					rules: [
						{ field: 'berth_planner_pro', op: 'eq', data: berthPlannerPro },
						{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') },
						{ field: 'location_id', op: 'in', data: userQuaysId }
					]
				};
			}

			let op = 'eq';
			let data = defaultQuay.id;
			if (defaultQuay.groupIds) {
				op = 'in';
				data = defaultQuay.groupIds;
			}

			return {
				rules: [
					{ field: 'berth_planner_pro', op: 'eq', data: berthPlannerPro },
					{ field: 'location_id', op, data }
				]
			};
		},
		compClass() {
			return this.$store.getters.isMobile ? 'pui-toolbar__actionBtn--mobile' : 'pui-toolbar__actionBtn--desktop';
		}
	},
	mounted() {
		this.$puiEvents.$on('stop-performance-exportSelected', (obj) => {
			this.exportData(obj.format, obj.data);
		});
		this.$puiEvents.$on('stop-performance-dashboard', () => {
			this.showDashboard();
		});
	},
	beforeDestroy() {
		this.$puiEvents.$off('stop-performance-exportSelected');
		this.$puiEvents.$off('stop-performance-dashboard');
	},
	methods: {
		renderData(data, row, prop) {
			if (row[prop]) {
				if (this.exceedTargetIdleTime(row[prop], prop, row.portid, row.locationid)) {
					return `<label class="stopperformance_exceeded">${data}</label>`;
				}
				return `<label class="stopperformance_not_exceeded">${data}</label>`;
			}
			return data;
		},
		exportData(format, filterData) {
			this.$store.state.global.puiloader.message = this.$t('puidatatables.downloading');
			this.$store.state.global.puiloader.show = true;
			trackDownload();

			const currentConfiguration = this.$store.getters.getCurrentGridConfiguration(this.modelName);
			const currentColumns = currentConfiguration.configuration.columns
				.filter((column) => {
					return column.visible === undefined || column.visible === true || column.visibility === 'visible';
				})
				.map((column, index) => {
					let dateformat = this.$store.getters.javaDateFormat;
					if (
						!(
							this.$children[0].modelColumnDefs &&
							this.$children[0].modelColumnDefs[column.name] &&
							this.$children[0].modelColumnDefs[column.name].withoutHours
						)
					) {
						dateformat += ' ' + this.$store.getters.timeFormat;
					}
					return {
						order: index,
						title: this.$t(column.title),
						name: column.name,
						dateformat: dateformat
					};
				});
			const currentFilter = this.$children[0].getCurrentFilter();
			const currentOrder = this.$children[0].getCurrentSorting();
			const currentSearch = this.$children[0].getCurrentSearch();
			let queryText = '';
			let queryFields = [];
			if (currentSearch !== undefined && currentSearch !== null) {
				queryText = currentSearch[0];
				queryFields = currentSearch[1].map((field) => {
					return field.name;
				});
			}

			currentFilter.rules.push({ field: 'id', op: 'in', data: filterData });

			this.$puiRequests.downloadFileRequest(
				'post',
				`/${this.modelName}/export`,
				{
					model: this.modelName,
					exportTitle: this.$t('menu.' + this.modelName),
					exportType: format,
					exportColumns: currentColumns,
					filter: currentFilter,
					order: currentOrder,
					queryText: queryText,
					queryFields: queryFields,
					queryFlexible: false,
					queryLang: this.$store.getters.getUserLanguage
				},
				(response) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(new window.Blob([response.data]));
					link.download = this.$puiUtils.getFilenameFromHeaders(response.headers);
					link.click();
					this.$store.state.global.puiloader.message = null;
					this.$store.state.global.puiloader.show = false;
				},
				(error) => {
					console.log(error);
					this.$store.state.global.puiloader.message = null;
					this.$store.state.global.puiloader.show = false;
				}
			);
		},
		showDashboard() {
			const portId = window.localStorage.getItem('workingPortsId');
			const dashboardId = this.$store.getters.getDashboardIdBy(portId);
			if (!dashboardId) {
				console.error(this.$t('errors.nodashboardconfigured'));
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				return;
			}

			this.$puiRequests.getRequest(
				'/puidashboard/get',
				{ id: dashboardId },
				(response) => {
					if (response && response.data) {
						this.dashboard = {
							id: response.data.id,
							name: response.data.name,
							definition: JSON.parse(response.data.definition)
						};
						this.dashboardDialog = true;
					}
				},
				(error) => {
					console.error(error);
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				}
			);
		},
		showLegend() {
			this.legendDialog = true;
		},
		closeDashboard() {
			this.dashboardDialog = false;
			this.dashboard = {};
			this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
		},
		closeLegend() {
			this.legendDialog = false;
		}
	}
};
</script>

<style>
.stopperformance_exceeded {
	color: red;
}
.stopperformance_not_exceeded {
	color: green;
}
</style>
