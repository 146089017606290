<template>
	<v-card>
		<v-card-title></v-card-title>
		<v-card-text>
			<pui-dashboard :defaultDashboard="dashboard" />
		</v-card-text>
		<v-divider></v-divider>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" text @click="closeDialog()">Ok</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	props: {
		dashboard: {
			type: Object,
			required: true
		}
	},
	methods: {
		closeDialog() {
			this.$emit('close-dialog');
		}
	}
}
</script>
