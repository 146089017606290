<template>
	<v-card>
		<v-card-title>
			<v-text-field v-model="search" append-icon="far fa-search" label="Search" single-line hide-details />
		</v-card-title>
		<v-card-text>
			<v-data-table :headers="headers" :items="items" :search="search" hide-default-header hide-default-footer />
		</v-card-text>
		<v-divider/>
		<v-card-actions>
			<v-spacer />
			<v-btn color="primary" text @click="closeDialog()">Ok</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	props: {
		modelName: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			headers: [
				{ text: 'name', value: 'name' },
				{ text: 'description', value: 'description' }
			],
			items: [],
			search: undefined
		};
	},
	created() {
		this.fillItems();
	},
	methods: {
		fillItems() {
			const currentConfiguration = this.$store.getters.getCurrentGridConfiguration(this.modelName);
			const currentColumns = currentConfiguration.configuration.columns.filter((column) => {
					return column.visible === undefined || column.visible === true || column.visibility === 'visible';
				});

			currentColumns.forEach(column => {
				const name = this.$t('grid.stopperformance.' + column.name);
				const description = this.$t('grid.stopperformance.' + column.name + '_desc');

				if (name && name != ('grid.stopperformance.' + column.name)
					&& description && description != ('grid.stopperformance.' + column.name + '_desc')) {
					this.items.push({ name,  description });
				}
			});
		},
		closeDialog() {
			this.$emit('close-dialog');
		}
	}
};
</script>
